"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var a = p => " DEFINE sql:signal-void-variables 0 PREFIX z: <https://slovník.gov.cz/základní/pojem/> PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> PREFIX skos: <http://www.w3.org/2004/02/skos/core#> PREFIX owl: <http://www.w3.org/2002/07/owl#> CONSTRUCT { ?attribute a z:typ-vlastnosti ; rdfs:domain " +
	p.node +
	" ; skos:prefLabel ?attributeLabel ; skos:definition ?attributeDefinition ; skos:inScheme ?attributeGlossary ; owl:maxQualifiedCardinality ?max ; owl:minQualifiedCardinality ?min . } WHERE { { ?attribute rdfs:subClassOf [ owl:allValuesFrom " +
	p.node +
	" ; owl:onProperty z:je-vlastností ] . } UNION { " +
	p.node +
	" rdfs:subClassOf [ owl:allValuesFrom ?attribute ; owl:onProperty z:má-vlastnost ] . } ?attribute a z:typ-vlastnosti ; skos:prefLabel ?attributeLabel ; skos:inScheme ?attributeGlossary . OPTIONAL { SELECT ?attribute (COUNT(*) AS ?count) WHERE { { ?restriction rdfs:subClassOf [ owl:allValuesFrom ?attribute ; owl:onProperty z:je-vlastností ] } UNION { ?attribute rdfs:subClassOf [ owl:allValuesFrom [] ; owl:onProperty z:má-vlastnost ] . } } } FILTER(BOUND(?count)=false) OPTIONAL { " +
	p.node +
	" rdfs:subClassOf [ a owl:Restriction ; owl:onProperty <https://slovník.gov.cz/základní/pojem/má-vlastnost> ; owl:onClass ?attribute ; owl:maxQualifiedCardinality ?max ] . } OPTIONAL { " +
	p.node +
	" rdfs:subClassOf [ a owl:Restriction ; owl:onProperty <https://slovník.gov.cz/základní/pojem/má-vlastnost> ; owl:onClass ?attribute ; owl:minQualifiedCardinality ?min ] . } OPTIONAL { " +
	p.node +
	" rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/je-vlastností> ] ; owl:onClass ?attribute ; owl:maxQualifiedCardinality ?max ] . } OPTIONAL { " +
	p.node +
	" rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/je-vlastností> ] ; owl:onClass ?attribute ; owl:minQualifiedCardinality ?min ] . } OPTIONAL { ?attribute skos:definition ?attributeDefinition } OPTIONAL { ?attribute skos:scopeNote ?attributeDefinition } } ";
exports.default = a;
