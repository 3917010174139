"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var a = p => " DEFINE sql:signal-void-variables 0 PREFIX z: <https://slovník.gov.cz/základní/pojem/> PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> PREFIX skos: <http://www.w3.org/2004/02/skos/core#> PREFIX owl: <http://www.w3.org/2002/07/owl#> CONSTRUCT { ?inwardsRelation a z:typ-vztahu ; rdfs:domain ?domainElement ; rdfs:range " +
	p.node +
	" ; skos:prefLabel ?inwardsRelationLabel ; skos:definition ?inwardsRelationDefinition ; skos:inScheme ?inwardsRelationGlossary ; rdfs:subPropertyOf ?parentRelation ; <__domain_cardinality> ?domain_cardinality ; <__range_cardinality> ?range_cardinality . ?domain_cardinality owl:maxQualifiedCardinality ?domain_max ; owl:minQualifiedCardinality ?domain_min . ?range_cardinality owl:maxQualifiedCardinality ?range_max ; owl:minQualifiedCardinality ?range_min . ?domainElement a z:typ-objektu ; rdfs:subClassOf ?ancestor ; skos:prefLabel ?domainLabel ; skos:definition ?domainDefinition ; skos:inScheme ?domainGlossary . } WHERE { ?inwardsRelation a z:typ-vztahu ; skos:prefLabel ?inwardsRelationLabel ; skos:inScheme ?inwardsRelationGlossary . { ?inwardsRelation rdfs:subClassOf ?rangeDefinition . ?rangeDefinition owl:allValuesFrom " +
	p.node +
	" ; owl:onProperty z:má-vztažený-prvek-2 . } { ?inwardsRelation rdfs:subClassOf ?domainDefinition . ?domainDefinition owl:allValuesFrom ?domainElement ; owl:onProperty z:má-vztažený-prvek-1 . } OPTIONAL { ?inwardsRelation skos:definition ?inwardsRelationDefinition } OPTIONAL { ?inwardsRelation skos:scopeNote ?inwardsRelationDefinition } ?domainElement skos:prefLabel ?domainLabel ; skos:inScheme ?domainGlossary . OPTIONAL { ?domainElement skos:definition ?domainDefinition } OPTIONAL { ?domainElement skos:scopeNote ?domainDefinition } OPTIONAL { ?domainElement rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/má-vztažený-prvek-1> ]; owl:onClass ?outwardsRelation ; owl:minQualifiedCardinality ?range_min ] . } OPTIONAL { ?domainElement rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/má-vztažený-prvek-1> ]; owl:onClass ?outwardsRelation ; owl:maxQualifiedCardinality ?range_max ] . } OPTIONAL { " +
	p.node +
	" rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/má-vztažený-prvek-2> ]; owl:onClass ?inwardsRelation ; owl:minQualifiedCardinality ?domain_min ] . } OPTIONAL { " +
	p.node +
	" rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/má-vztažený-prvek-2> ]; owl:onClass ?inwardsRelation ; owl:maxQualifiedCardinality ?domain_max ] . } BIND(IRI(CONCAT(STR(?outwardsRelation), '#domain-cardinality')) as ?domain_cardinality) BIND(IRI(CONCAT(STR(?outwardsRelation), '#range-cardinality')) as ?range_cardinality) } ";
exports.default = a;
