"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var a = p => " DEFINE sql:signal-void-variables 0 PREFIX z: <https://slovník.gov.cz/základní/pojem/> PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> PREFIX skos: <http://www.w3.org/2004/02/skos/core#> PREFIX owl: <http://www.w3.org/2002/07/owl#> CONSTRUCT { ?outwardsRelation a z:typ-vztahu ; rdfs:domain " +
	p.node +
	" ; rdfs:range ?rangeElement ; skos:prefLabel ?outwardsRelationLabel ; skos:definition ?outwardsRelationDefinition ; skos:inScheme ?outwardsRelationGlossary . ?rangeElement a z:typ-objektu ; rdfs:subClassOf ?ancestor ; skos:prefLabel ?rangeLabel ; skos:definition ?rangeDefinition ; skos:inScheme ?rangeGlossary ; <__is_ciselnik> ?is_ciselnik . } WHERE { { ?attribute rdfs:subClassOf [ owl:allValuesFrom " +
	p.node +
	" ; owl:onProperty z:je-vlastností ] . } UNION { " +
	p.node +
	" rdfs:subClassOf [ owl:allValuesFrom ?attribute ; owl:onProperty z:má-vlastnost ] . } ?attribute a z:typ-vlastnosti ; skos:prefLabel ?attributeLabel ; skos:inScheme ?attributeGlossary . { ?restriction rdfs:subClassOf [ owl:allValuesFrom ?attribute ; owl:onProperty z:je-vlastností ] } UNION { ?attribute rdfs:subClassOf [ owl:allValuesFrom [] ; owl:onProperty z:má-vlastnost ] . } OPTIONAL { ?attribute skos:definition ?attributeDefinition } OPTIONAL { ?attribute skos:scopeNote ?attributeDefinition } BIND(IRI(REPLACE(STR(?attribute), \"/pojem/\", \"/pojem-z-cim-transformace/\")) AS ?outwardsRelation) BIND(?attribute AS ?rangeElement) BIND(?attributeLabel AS ?outwardsRelationLabel) BIND(?attributeDefinition AS ?outwardsRelationDefinition) BIND(?attributeGlossary AS ?outwardsRelationGlossary) BIND(?parentAttribute AS ?parentRelation) BIND(?attributeLabel AS ?rangeLabel) BIND(?attributeDefinition AS ?rangeDefinition) BIND(?attributeGlossary AS ?rangeGlossary) BIND(EXISTS {?rangeElement rdfs:subClassOf+ <https://slovník.gov.cz/datový/číselníky/pojem/položka-číselníku>} as ?is_ciselnik) } ";
exports.default = a;
