"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var a = p => " DEFINE sql:signal-void-variables 0 PREFIX z: <https://slovník.gov.cz/základní/pojem/> PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> PREFIX skos: <http://www.w3.org/2004/02/skos/core#> PREFIX owl: <http://www.w3.org/2002/07/owl#> CONSTRUCT { ?outwardsRelation a z:typ-vztahu ; rdfs:domain " +
	p.node +
	" ; rdfs:range ?rangeElement ; skos:prefLabel ?outwardsRelationLabel ; skos:definition ?outwardsRelationDefinition ; skos:inScheme ?outwardsRelationGlossary ; <__domain_cardinality> ?domain_cardinality ; <__range_cardinality> ?range_cardinality . ?domain_cardinality owl:maxQualifiedCardinality ?domain_max ; owl:minQualifiedCardinality ?domain_min . ?range_cardinality owl:maxQualifiedCardinality ?range_max ; owl:minQualifiedCardinality ?range_min . ?rangeElement a z:typ-objektu ; skos:prefLabel ?rangeLabel ; skos:definition ?rangeDefinition ; skos:inScheme ?rangeGlossary ; <__is_ciselnik> ?is_ciselnik . } WHERE { ?outwardsRelation a z:typ-vztahu ; skos:prefLabel ?outwardsRelationLabel ; skos:inScheme ?outwardsRelationGlossary . { ?outwardsRelation rdfs:subClassOf [ owl:allValuesFrom " +
	p.node +
	" ; owl:onProperty z:má-vztažený-prvek-1 ] } { ?outwardsRelation rdfs:subClassOf [ owl:allValuesFrom ?rangeElement ; owl:onProperty z:má-vztažený-prvek-2 ] } OPTIONAL { ?outwardsRelation skos:definition ?outwardsRelationDefinition } OPTIONAL { ?outwardsRelation skos:scopeNote ?outwardsRelationDefinition } ?rangeElement skos:prefLabel ?rangeLabel ; skos:inScheme ?rangeGlossary . OPTIONAL { ?rangeElement skos:definition ?rangeDefinition } OPTIONAL { ?rangeElement skos:scopeNote ?rangeDefinition } BIND(EXISTS {?rangeElement rdfs:subClassOf+ <https://slovník.gov.cz/datový/číselníky/pojem/položka-číselníku>} as ?is_ciselnik) OPTIONAL { " +
	p.node +
	" rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/má-vztažený-prvek-1> ]; owl:onClass ?outwardsRelation ; owl:minQualifiedCardinality ?range_min ] . } OPTIONAL { " +
	p.node +
	" rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/má-vztažený-prvek-1> ]; owl:onClass ?outwardsRelation ; owl:maxQualifiedCardinality ?range_max ] . } OPTIONAL { ?rangeElement rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/má-vztažený-prvek-2> ]; owl:onClass ?outwardsRelation ; owl:minQualifiedCardinality ?domain_min ] . } OPTIONAL { ?rangeElement rdfs:subClassOf [ a owl:Restriction ; owl:onProperty [ owl:inverseOf <https://slovník.gov.cz/základní/pojem/má-vztažený-prvek-2> ]; owl:onClass ?outwardsRelation ; owl:maxQualifiedCardinality ?domain_max ] . } BIND(IRI(CONCAT(STR(?outwardsRelation), '#domain-cardinality')) as ?domain_cardinality) BIND(IRI(CONCAT(STR(?outwardsRelation), '#range-cardinality')) as ?range_cardinality) } ";
exports.default = a;
